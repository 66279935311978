<template>
  <core-section id="what-my-clients-say">
    <abstract-4 />
    <core-heading v-if="schema.clients.length > 0">What my clients say.</core-heading>
    <v-col
      v-for="(client, i) in schema.clients" :key="`quote-${i}`" cols="12" sm="4">
      <client :value="client" />
    </v-col>
    <template v-if="schema.awards.length">
      <div class="mb-3" style="max-width: 500px;">
        <core-heading>Awards</core-heading>
        <v-list class="transparent">
          <v-list-item v-for="(award, i) in schema.awards" :key="`award-${i}`">
            <v-list-item-action>
              <v-icon color="primary">
                mdi-trophy-award
              </v-icon>
            </v-list-item-action>
            <v-list-item-title v-text="award" class="caption grey--text text--lighten-1" />
          </v-list-item>
        </v-list>
      </div>
    </template>
  </core-section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'WhatMyClientsSay',

  components: {
    Abstract4: () => import('@/components/Abstract4'),
    Client: () => import('@/components/Client')
  },
  computed: {
    ...mapState('app', ['schema'])
  }
}
</script>
